let User = function (tokenClient, serviceAddr) {
  let TokenClient = tokenClient
  let ServiceBaseAddress = serviceAddr
  let UserSession = "UserSession"
  /**
   * 用户登录
   * @param {any} username
   * @param {any} password
   */
  this.Login = function (username, password) {
    TokenClient.SetUser(username, password)
    if (TokenClient.Token()) {
      analysisToken(TokenClient.Token().AccessToken)
      return true
    } else {
      return false
    }
  }
  this.Manager = function (organizationId, keyWord, success, error) {
    var url = ServiceBaseAddress + "/api/User/Key/Manager"
    if (organizationId) {
      url += `/${organizationId}`
    } else {
      url += "/0"
    }
    if (keyWord) {
      url += `/${keyWord}`
    } else {
      url += "/%20"
    }
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }

  this.OrganizationUsers = function (organizationId, success, error) {
    var url = ServiceBaseAddress + "/api/User/Organization/" + organizationId
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  this.User = function (organizationId, success, error) {
    var url = ServiceBaseAddress + "/api/User/Key/Users/"
    if (organizationId) {
      url += organizationId + "/0"
    } else {
      url += "0/0"
    }
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  /**
   * 解绑用户
   * @param userId
   * @param success
   * @param error
   * @constructor
   */
  // this.Unbound = function (userId, success, error) {
  // 	var url =
  // 		ServiceBaseAddress +
  // 		'/api/User/RelieveUserOrganizationRelation/' +
  // 		userId
  // 	TokenClient.Post(
  // 		url,
  // 		true,
  // 		{ 'Content-Type': 'application/json' },
  // 		userId,
  // 		function (data) {
  // 			if (success) {
  // 				var jsonObj = JSON.parse(data)
  // 				success(jsonObj)
  // 			}
  // 		},
  // 		error
  // 	)
  // }
  /**
   * 解绑员工
   * @param {*} userId
   * @param {*} success
   * @param {*} error
   */
  this.unbindingUser = function (userId, success, error) {
    var url = ServiceBaseAddress + `/api/Agent/AgentUnbindUser/${userId}`
    TokenClient.Put(
      url,
      true,
      { "Content-Type": "application/json" },
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  this.StaffDetail = function (userId, success, error) {
    var url = ServiceBaseAddress + "/api/User/Info/" + userId
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  this.EnableUser = function (userId, success, error) {
    var url = ServiceBaseAddress + "/api/User/Enable/" + userId
    TokenClient.Put(
      url,
      true,
      { "Content-Type": "application/json" },
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  this.DisableUser = function (userId, success, error) {
    var url = ServiceBaseAddress + "/api/User/Disable/" + userId
    TokenClient.Put(
      url,
      true,
      { "Content-Type": "application/json" },
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  /**
   * 新增用户
   * @param {any} name 用户姓名
   * @param {any} password 登录密码
   * @param {any} mobilePhone 手机号
   * @param {any} email 电子邮箱
   * @param {any} sex 性别，1：男  0：女
   * @param {any} birthday 出生日期， yyyy-MM-dd
   * @param {any} organizationId 所属组织机构
   * @param {any} departmentId 所属部门
   * @param {any} titleId 所属职称
   * @param {any} postId 所属职位
   * ��ȡ�����û�
   * @param {any} success
   * @param {any} error
   */
  this.AllUser = function (success, error) {
    var url = ServiceBaseAddress + "/api/User"
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  /**
   * �����û�
   * @param {any} name �û�����
   * @param {any} password ��¼����
   * @param {any} mobilePhone �ֻ���
   * @param {any} email ��������
   * @param {any} sex �Ա�1����  0��Ů
   * @param {any} birthday �������ڣ� yyyy-MM-dd
   * @param {any} organizationId ������֯����
   * @param {any} departmentId ��������
   * @param {any} titleId ����ְ��
   * @param {any} postId ����ְλ
   * @param {any} success
   * @param {any} error
   */
  this.Add = function (userInfo, success, error) {
    var url = ServiceBaseAddress + "/api/User"
    TokenClient.Post(
      url,
      true,
      { "Content-Type": "application/json" },
      userInfo,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  this.ExistUserAccount = function (userInfo, success, error) {
    var url = ServiceBaseAddress + "/api/User/ExistUserAccount"
    TokenClient.Post(
      url,
      true,
      { "Content-Type": "application/json" },
      userInfo,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  this.ExistUserOrganization = function (account, success, error) {
    var url = ServiceBaseAddress + "/api/User/ExistUserOrganization/" + account
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  this.ProductOrganization = function (success, error) {
    var url = ServiceBaseAddress + "/api/Product/Organization"
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  /**
   * 修改用户信息
   * @param {any} userId
   * @param {any} name 用户姓名
   * @param {any} password 登录密码
   * @param {any} mobilePhone 手机号
   * @param {any} email 电子邮箱
   * @param {any} sex 性别，1：男  0：女
   * @param {any} birthday 出生日期， yyyy-MM-dd
   * @param {any} organizationId 所属组织机构
   * @param {any} departmentId 所属部门
   * @param {any} titleId 所属职称
   * @param {any} postId 所属职位
   * @param {any} success
   * @param {any} error
   */
  this.Edit = function (userInfo, success, error) {
    var url = ServiceBaseAddress + "/api/User"
    console.log(userInfo)
    TokenClient.Put(
      url,
      true,
      { "Content-Type": "application/json" },
      userInfo,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  /**
   * 删除用户
   * @param {any} userId
   * @param {any} success
   * @param {any} error
   */
  this.Remove = function (userId, success, error) {
    var url = ServiceBaseAddress + "/api/User/" + userId
    TokenClient.Delete(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  this.UserInfo = function () { }
  /**
   * 修改当前登录用户的密码
   * @param {any} oldPassword 旧密码
   * @param {any} newPassword 新密码
   * @param {any} success
   * @param {any} error
   */
  this.ChangePassword = function (oldPassword, newPassword, success, error) {
    var url = ServiceBaseAddress + "/api/User/ChangePassword"
    var param = {
      oldPassword: oldPassword,
      newPassword: newPassword,
    }
    TokenClient.Put(
      url,
      true,
      { "Content-Type": "application/json" },
      param,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  this.OrgResetPassword = function (param, success, error) {
    var url = ServiceBaseAddress + "/api/User/OrgResetPassword"
    TokenClient.Put(
      url,
      true,
      { "Content-Type": "application/json" },
      param,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  this.AgentDetails = function (agentId, success, error) {
    var url = ServiceBaseAddress + `/api/Agent/AgentDetails/${agentId}`
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  this.OrgLogoAll = function (orgId, key, success, error) {
    var url = ServiceBaseAddress + `/api/OrganizationLogo/OrgLogoAll/${orgId}/0/${key}/%20/1`
    TokenClient.Get(url, true, null, null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  this.OrgCustomer = function (orgId, success, error) {
    var url = ServiceBaseAddress + `/api/Organization/OrgCustomer/${orgId}`
    TokenClient.Get(url, true, null, null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  /**
   * 设定指定用户的密码
   * @param {any} userId
   * @param {any} password
   * @param {any} success
   * @param {any} error
   */
  this.ResetPassword = function (userId, password, success, error) {
    var url = ServiceBaseAddress + "/api/User/SetUserPassword"
    var param = {
      userId: userId,
      password: password,
    }
    TokenClient.Put(
      url,
      true,
      { "Content-Type": "application/json" },
      param,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  this.ResetUserPassword = function (item, success, error) {
    var url = ServiceBaseAddress + "/api/User/ResetUserPassword/" + item
    TokenClient.Post(
      url,
      true,
      { "Content-Type": "application/json" },
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  /*获取Session中的用户信息 */
  this.GetSessionUserInfo = function () {
    var result = sessionStorage.getItem("userInfo")
    if (result == null) {
      result = {}
    } else {
      result = JSON.parse(result)
    }
    return result
  }
  /**
   * haha
   */
  this.GetSessionUser = function () {
    var result = sessionStorage.getItem(UserSession)
    if (result == null) {
      this.AllUser(
        function (data) {
          sessionStorage.setItem(UserSession, JSON.stringify(data.data))
          result = data.data
        },
        function (err) {
          console.log(err)
        }
      )
    } else {
      result = JSON.parse(result)
    }
    return result
  }
  this.RefeUser = function (organizationId, departmentId, success, error) {
    var url = ServiceBaseAddress + "/api/User/Key/Users/" + organizationId
    if (departmentId) {
      url += "/" + departmentId
    } else {
      url
    }
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }

  this.ImportUserData = function (path, success, error) {
    var url = ServiceBaseAddress + `/api/User/ImportUserData?filePath=${path}`
    TokenClient.Post(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }

  this.SetMZUserRate = function (item, success, error) {
    var url = ServiceBaseAddress + "/api/User/SetMZUserRate"
    TokenClient.Put(
      url,
      true,
      { "Content-Type": "application/json" },
      item,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  this.SetMZUserRateBatch = function (item, success, error) {
    var url = ServiceBaseAddress + "/api/User/SetMZUserRateBatch";
    TokenClient.Put(
      url,
      true,
      { "Content-Type": "application/json" },
      item,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
  this.SetUserMZInfoBatch = function (item, success, error) {
    var url = ServiceBaseAddress + "/api/User/SetUserMZInfoBatch";
    TokenClient.Post(
      url,
      true,
      { "Content-Type": "application/json" },
      item,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };

  this.SetMZUserRateBatch = function (item, success, error) {
    var url = ServiceBaseAddress + "/api/User/SetMZUserRateBatch"
    TokenClient.Put(
      url,
      true,
      { "Content-Type": "application/json" },
      item,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  };

  this.SetUserMZInfo = function (item, success, error) {
    var url = ServiceBaseAddress + "/api/User/SetUserMZInfo"
    TokenClient.Post(
      url,
      true,
      { "Content-Type": "application/json" },
      item,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  this.SetUserCashPledge = function (item, success, error) {
    var url = ServiceBaseAddress + "/api/User/UserCashPledge"
    TokenClient.Put(
      url,
      true,
      { "Content-Type": "application/json" },
      item,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  /**
   * 充值记录
   * @param userId
   * @param success
   * @param error
   * @constructor
   */
  this.OrderInfo = function (userId, pageIndex, state = 0, success, error) {
    var url = ServiceBaseAddress + "/api/WeChatPay/OrderInfo/" + userId + `/%20`
    if (pageIndex) {
      url += `/${pageIndex}/${state}`
    }
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  this.getUserOperation = function (userId, userAccount, userName, clientId, operation, organizationId, remark, startDate, endDate, pageIndex, pageSize, success, error) {
    var url = ServiceBaseAddress + `/api/User/Operation`
    if (userId) {
      url += `/${userId}`
    } else {
      url += "/0"
    }
    if (userAccount) {
      url += `/${userAccount}`
    } else {
      url += "/%20"
    }
    if (userName) {
      url += `/${userName}`
    } else {
      url += "/%20"
    }
    if (clientId) {
      url += `/${clientId}`
    } else {
      url += "/%20"
    }
    if (operation) {
      url += `/${operation}/${organizationId}`
    } else {
      url += `/%20/${organizationId}`
    }
    if (remark) {
      url += `/${remark}`
    } else {
      url += "/%20"
    }
    if (startDate) {
      url += `/${startDate}`
    } else {
      url += "/%20"
    }
    if (endDate) {
      url += `/${endDate}`
    } else {
      url += "/%20"
    }
    if (pageIndex) {
      url += `/${pageIndex}`
    }
    if (pageSize) {
      url += `/${pageSize}`
    }
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  /**
   * 获取用户操作类型
   * @param userid
   * @param success
   * @param error
   * @constructor
   */
  this.getOperations = function (success, error) {
    var url = ServiceBaseAddress + "/api/User/Operations"
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  this.GetUserAll = function (organizationId, success, error) {
    var url = ServiceBaseAddress + "/api/Index/GetUserAll/" + organizationId
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }

  this.GetCertificateListPage = function (state, pageIndex, pageSize, success, error) {
    var url = ServiceBaseAddress + `/api/UserCertificateAudit/GetCertificateListPage/${state}`
    if (pageIndex) {
      url += `/${pageIndex}/${pageSize}`
    }
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }

  this.SubmitUserCertificateAudit = function (item, success, error) {
    var url = ServiceBaseAddress + '/api/UserCertificateAudit/SubmitUserCertificateAudit'
    TokenClient.Post(url,
      true,
      { "Content-Type": "application/json" },
      item,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
}

export { User }

//解析JWT
function analysisToken (token) {
  let strings = token.split(".") //截取token，获取载体
  var userinfo = JSON.parse(
    decodeURIComponent(
      escape(
        window.atob(strings[1].replace(/-/g, "+").replace(/_/g, "/")) //解析，需要吧‘_’,'-'进行转换否则会无法解析
      )
    )
  )
  if (userinfo.sub) {

    var user = {
      id: parseInt(userinfo.sub ?? 0), //用户ID
      name: userinfo.UserName, //用户姓名
      phone: userinfo.Phone, //电话号
      telephone: userinfo.telephone,
      address: userinfo.address,
      abstract: userinfo.abstract,
      sex: userinfo.Sex, //性别
      photo: userinfo.PhotoFile, //头像
      organizationId: parseInt(userinfo.OrganizationId ?? 0), //所属单位ID
      categoryId: parseInt(userinfo.CategoryId ?? 0), //角色类型
      organizationName: userinfo.OrganizationName, //所属单位名称
      departmentId: parseInt(userinfo.DepartmentId ?? 0), //所属部门ID
      department: userinfo.Department, //所属部门名称
      title: userinfo.Title, //职称
      titleId: parseInt(userinfo.TitleId ?? 0), //职称ID
      post: userinfo.Post, //职务
      postId: parseInt(userinfo.PostId ?? 0), //职务ID
      roleId: parseInt(userinfo.RoleId ?? 0),
      agentId: parseInt(userinfo.AgentId ?? 0), //代理商ID
      productId: parseInt(userinfo.ProductId ?? 0), //代理商ID
    }
    sessionStorage.setItem("userInfo", JSON.stringify(user)) //将token存储起来
  }
}
