import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import axios from 'axios'
import 'element-ui/lib/theme-chalk/index.css'
import "./theme/elementOrg/index.css" //机构主题样式
// import './theme/element/index.css' //煎配主题样式
import './assets/font/iconfont.css'
import './assets/font2/iconfont.css'
// import './assets/css/element-variables.scss'
import './assets/css/common.css'

// 你的其他JavaScript代码
import less from 'less'
import echarts from 'echarts'
import headCenter from './components/common/headCenter' //引入组件
import { TokenClient } from './components/TokenClient'
import ExportPdf from './components/common/ExportPdf.vue'
Vue.component('ExportPdf', ExportPdf)
import { ApiClient } from './components/TokenClient/ApiClient'
Vue.component('head-center', headCenter)
import ContentPreview from './components/DiseaseDomain/ContentPreview.vue'
Vue.component('ContentPreview', ContentPreview)
import bread from "./components/common/breadcrumb"
Vue.component('bread', bread)
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme
import tinymce from 'tinymce'
import VueTinymce from "@packy-tang/vue-tinymce"
import basePath from '../public/services.json'
// import exportPdf from './utils/exportPdf.js'
// Vue.use(exportPdf)
import websocket from "./utils/WebSocket"
Vue.prototype.$socket = websocket.Instance

import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
    // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
    ak: 'C3L8oD0NokcKDAyhtGZgTtwF1R4eqo7S'
})
// 导出表格
import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)
// 聊天
import JwChat from 'jwchat'
Vue.use(JwChat)
// 定义全局自定义过滤器
import { fliters } from './components/common/fliters'
Object.keys(fliters).forEach(key => {
    Vue.filter(key, fliters[key])
})
import $ from 'jquery'
window.jQuery = $
window.$ = $
//安装组件
Vue.use(VueTinymce)

Vue.use(VueQuillEditor /* { default global options } */)
Vue.use(less)
Vue.use(ElementUI)
Vue.config.productionTip = false
Vue.prototype.$echarts = echarts
Vue.prototype.$http = axios
Vue.prototype.$basePath = basePath
Vue.prototype.$fliters = fliters
Vue.prototype.exchange = null
Vue.prototype.isLogin = false,
    Vue.prototype.loading = function () {
        const loading = this.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
        })
        return loading
    }
initServer()

axios.defaults.baseUrl = 'http://127.0.0.1:3333/'
Vue.directive('title', {
    inserted: function (el, binding) {
        document.title = el.dataset.title
    },
})
//事件总线
var EventBus = new Vue()
Object.defineProperties(Vue.prototype, {
    $bus: {
        get: function () {
            return EventBus
        },
    },
})
// 导航守卫
//使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登录
router.beforeEach((to, from, next) => {
    if (to.path != '/login') {
        if (Vue.prototype.TokenClient.RefreshToken()) {
            next()
        } else {
            next({
                path: '/login',
                query: { redirect: to.fullPath },
            })
        }
    } else {
        next()
    }
})

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app')

function initServer () {
    ApiClient.Get(
        '/services.json',
        false,
        [],
        null,
        function (res) {
            var data = JSON.parse(res)
            Vue.prototype.Services = {
                Authorization: data.Authorization,
                Notice: data.Notice,
                Disease: data.Disease,
                Drug: data.Drug,
                Diagnosis: data.Diagnosis,
                Healthy: data.Healthy,
                Referral: data.Referral,
                Chathub: data.Chathub,
                Shopping: data.Shopping,
                ChathubWss: data.ChathubWss,
                MsgWss: data.MsgWss
            }
            Vue.prototype.IdentityServer = data.IdentityServer
            Vue.prototype.ClientId = data.ClientId
            Vue.prototype.Secret = data.Secret
            Vue.prototype.YunClient = data.YunClient
            Vue.prototype.FairClient = data.FairClient
            Vue.prototype.TotalClient = data.TotalClient
            Vue.prototype.IsChangeLoginPage = data.IsChangeLoginPage
            // Vue.prototype.CloudOrganization = data.YunClientList.split(',').filter(i => i == data.ClientId)[0]
            // Vue.prototype.FairOrganization = data.FairClientList.split(',').filter(i => i == data.ClientId)[0]

            Vue.prototype.TokenClient = new TokenClient(
                Vue.prototype.IdentityServer,
                Vue.prototype.ClientId,
                Vue.prototype.Secret
            )
        },
        function (ex) {
            console.log('加载配置文件错误！')
        }
    )
}
