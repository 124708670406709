/*
 * @Author: your name
 * @Date: 2021-06-25 17:56:29
 * @LastEditTime: 2021-06-28 16:05:12
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \MedicineDiagnosis.HospitalManagement.Web\src\router\index.js
 */
import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    redirect: "/index",
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue") /*使用箭头函数的使用意义：按需加载（懒加载）*/,
  },
  {
    path: "/blank",
    name: "blank",
  },

  {
    path: "/",
    name: "Outpatient",
    component: () => import("../views/Home/index.vue") /*使用箭头函数的使用意义：按需加载（懒加载）*/,
    meta: {
      title: "门诊管理",
    },
    children: [
      {
        path: "/Outpatient/CheckItem" /*银行卡管理*/,
        name: "CheckItem",
        component: () => import("../views/Home/Outpatient/CheckItem.vue"),
        meta: {
          title: "收费项目管理",
        },
      },
      {
        path: "/Outpatient/PersonCommonPrescriptions" /*银行卡管理*/,
        name: "PersonCommonPrescriptions",
        component: () => import("../views/Home/Outpatient/PersonCommonPrescriptions.vue"),
        meta: {
          title: "个人常用处方",
        },
      },
      {
        path: "/Outpatient/PersonCommonPrescriptionsInfo" /*银行卡管理*/,
        name: "PersonCommonPrescriptionsInfo",
        component: () => import("../views/Home/Outpatient/PersonCommonPrescriptionsInfo.vue"),
        meta: {
          title: "个人常用处方详情",
        },
      },
      {
        path: "/Outpatient/OrgCommonPrescriptions" /*银行卡管理*/,
        name: "OrgCommonPrescriptions",
        component: () => import("../views/Home/Outpatient/OrgCommonPrescriptions.vue"),
        meta: {
          title: "机构常用处方",
        },
      },
      {
        path: "/Outpatient/AddProductDrugPrescription" /*新增成药处方*/,
        name: "AddProductDrugPrescription",
        component: () => import("../views/Home/Outpatient/AddProductDrugPrescription.vue"),
        meta: {
          title: "成药处方",
        },
      },
      {
        path: "/Outpatient/AddPillDrugPrescription" /*新增饮片处方*/,
        name: "AddPillDrugPrescription",
        component: () => import("../views/Home/Outpatient/AddPillDrugPrescription.vue"),
        meta: {
          title: "方剂处方",
        },
      },
      {
        path: "/Outpatient/AddInfusionDrugPrescription" /*银行卡管理*/,
        name: "AddInfusionDrugPrescription",
        component: () => import("../views/Home/Outpatient/AddInfusionDrugPrescription.vue"),
        meta: {
          title: "输液处方",
        },
      },
      {
        path: "/Outpatient/CommonMedicalAdvice" /*银行卡管理*/,
        name: "CommonMedicalAdvice",
        component: () => import("../views/Home/Outpatient/CommonMedicalAdvice.vue"),
        meta: {
          title: "常用医嘱",
        },
      },
      {
        path: "/Outpatient/Usage" /*银行卡管理*/,
        name: "Usage",
        component: () => import("../views/Home/Outpatient/Usage.vue"),
        meta: {
          title: "服用要求",
        },
      },
      {
        path: "/Outpatient/ReturnReason" /*银行卡管理*/,
        name: "ReturnReason",
        component: () => import("../views/Home/Outpatient/ReturnReason.vue"),
        meta: {
          title: "退库原因设置",
        },
      },
      {
        path: "/Outpatient/DrugManagement" /*续费升级订单*/,
        name: "DrugManagement",
        component: () => import("../views/Home/Outpatient/DrugManagement.vue"),
        meta: {
          title: "药品信息管理",
        },
      },
      {
        path: "/Outpatient/AddDrug" /*续费升级订单*/,
        name: "AddDrug",
        component: () => import("../views/Home/Outpatient/AddDrug.vue"),
        meta: {
          title: "新增药品",
        },
      },
      {
        path: "/Outpatient/InventoryManagement" /*续费升级订单*/,
        name: "InventoryManagement",
        component: () => import("../views/Home/Outpatient/InventoryManagement.vue"),
        meta: {
          title: "库存管理",
        },
      },
      {
        path: "/Outpatient/InventorySummary" /*续费升级订单*/,
        name: "InventorySummary",
        component: () => import("../views/Home/Outpatient/InventorySummary.vue"),
        meta: {
          title: "库存汇总统计",
        },
      },
      {
        path: "/Outpatient/ReturnRecord" /*续费升级订单*/,
        name: "ReturnRecord",
        component: () => import("../views/Home/Outpatient/ReturnRecord.vue"),
        meta: {
          title: "退库记录",
        },
      },
      {
        path: "/Outpatient/DrugsWarehousing" /*续费升级订单*/,
        name: "DrugsWarehousing",
        component: () => import("../views/Home/Outpatient/DrugsWarehousing.vue"),
        meta: {
          title: "药品入库",
        },
      },
      {
        path: "/Outpatient/InventoryAlert" /*续费升级订单*/,
        name: "InventoryAlert",
        component: () => import("../views/Home/Outpatient/InventoryAlert.vue"),
        meta: {
          title: "库存预警",
        },
      },
      {
        path: "/Outpatient/ExpireAlert" /*续费升级订单*/,
        name: "ExpireAlert",
        component: () => import("../views/Home/Outpatient/ExpireAlert.vue"),
        meta: {
          title: "效期提醒",
        },
      },
      {
        path: "/Outpatient/WarehousingLog" /*续费升级订单*/,
        name: "WarehousingLog",
        component: () => import("../views/Home/Outpatient/WarehousingLog.vue"),
        meta: {
          title: "入库日志",
        },
      },
      {
        path: "/Outpatient/SellingDrugs" /*续费升级订单*/,
        name: "SellingDrugs",
        component: () => import("../views/Home/Outpatient/SellingDrugs.vue"),
        meta: {
          title: "直接售药",
        },
      },
      {
        path: "/Outpatient/AddPatient" /*续费升级订单*/,
        name: "AddPatient",
        component: () => import("../views/Home/Outpatient/AddPatient.vue"),
        meta: {
          title: "添加患者信息",
        },
      },
      {
        path: "/Outpatient/NotChargedList" /*续费升级订单*/,
        name: "NotChargedList",
        component: () => import("../views/Home/Outpatient/NotChargedList.vue"),
        meta: {
          title: "待收费",
        },
      },
      {
        path: "/Outpatient/ChargedList" /*续费升级订单*/,
        name: "ChargedList",
        component: () => import("../views/Home/Outpatient/ChargedList.vue"),
        meta: {
          title: "已收费",
        },
      },
      {
        path: "/Outpatient/SellingDrugsOrderList" /*续费升级订单*/,
        name: "SellingDrugsOrderList",
        component: () => import("../views/Home/Outpatient/SellingDrugsOrderList.vue"),
        meta: {
          title: "直接售药订单",
        },
      },
      {
        path: "/Outpatient/ChargedOrRefund" /*续费升级订单*/,
        name: "ChargedOrRefund",
        component: () => import("../views/Home/Outpatient/ChargedOrRefund.vue"),
        meta: {
          title: "详情",
        },
      },
      {
        path: "/Outpatient/OrderInfo" /*续费升级订单*/,
        name: "OrderInfo",
        component: () => import("../views/Home/Outpatient/OrderInfo.vue"),
        meta: {
          title: "详情",
        },
      },
      {
        path: "/Outpatient/RefundList" /*续费升级订单*/,
        name: "RefundList",
        component: () => import("../views/Home/Outpatient/RefundList.vue"),
        meta: {
          title: "退费",
        },
      },
      {
        path: "/Outpatient/RefundReviewList" /*续费升级订单*/,
        name: "RefundReviewList",
        component: () => import("../views/Home/Outpatient/RefundReviewList.vue"),
        meta: {
          title: "退费审核",
        },
      },
      {
        path: "/Outpatient/CreditManagement" /*续费升级订单*/,
        name: "CreditManagement",
        component: () => import("../views/Home/Outpatient/CreditManagement.vue"),
        meta: {
          title: "挂账管理",
        },
      },
      {
        path: "/Outpatient/CreditInfo" /*续费升级订单*/,
        name: "CreditInfo",
        component: () => import("../views/Home/Outpatient/CreditInfo.vue"),
        meta: {
          title: "挂账详情",
        },
      },
      {
        path: "/Outpatient/CreateOrder" /*续费升级订单*/,
        name: "CreateOrder",
        component: () => import("../views/Home/Outpatient/CreateOrder.vue"),
        meta: {
          title: "退费",
        },
      },
      {
        path: "/Outpatient/STATS/IncomeStat",
        name: "IncomeStat",
        component: () => import("../views/Home/Outpatient/STATS/IncomeStat.vue"),
        meta: {
          title: "门诊收入统计",
        },
      },
      {
        path: "/Outpatient/STATS/RefundStat",
        name: "RefundStat",
        component: () => import("../views/Home/Outpatient/STATS/RefundStat.vue"),
        meta: {
          title: "门诊退费统计",
        },
      },
      {
        path: "/Outpatient/STATS/DrugSalesStat",
        name: "DrugSalesStat",
        component: () => import("../views/Home/Outpatient/STATS/DrugSalesStat.vue"),
        meta: {
          title: "药品销售统计",
        },
      },
      {
        path: "/Outpatient/STATS/DoctorSalesStat",
        name: "DoctorSalesStat",
        component: () => import("../views/Home/Outpatient/STATS/DoctorSalesStat.vue"),
        meta: {
          title: "医生业绩统计",
        },
      },
      {
        path: "/Outpatient/STATS/OtherStat",
        name: "OtherStat",
        component: () => import("../views/Home/Outpatient/STATS/OtherStat.vue"),
        meta: {
          title: "其它费用统计",
        },
      },
      {
        path: "/Outpatient/STATS/ReviewerFeesStat",
        name: "ReviewerFeesStat",
        component: () => import("../views/Home/Outpatient/STATS/ReviewerFeesStat.vue"),
        meta: {
          title: "审方费用统计",
        },
      },
      {
        path: "/Outpatient/STATS/DepatmentStat",
        name: "DepatmentStat",
        component: () => import("../views/Home/Outpatient/STATS/DepatmentStat.vue"),
        meta: {
          title: "部门费用统计",
        },
      },
      {
        path: "/Outpatient/AuditingSTATS/IncomeStat",
        name: "IncomeStat",
        component: () => import("../views/Home/Outpatient/AuditingSTATS/IncomeStat.vue"),
        meta: {
          title: "门诊收入统计",
        },
      },
      {
        path: "/Outpatient/AuditingSTATS/RefundStat",
        name: "RefundStat",
        component: () => import("../views/Home/Outpatient/AuditingSTATS/RefundStat.vue"),
        meta: {
          title: "门诊退费统计",
        },
      },
      {
        path: "/Outpatient/AuditingSTATS/DoctorSalesStat",
        name: "DoctorSalesStat",
        component: () => import("../views/Home/Outpatient/AuditingSTATS/DoctorSalesStat.vue"),
        meta: {
          title: "医生业绩统计",
        },
      },
      {
        path: "/Outpatient/AuditingSTATS/OtherStat",
        name: "OtherStat",
        component: () => import("../views/Home/Outpatient/AuditingSTATS/OtherStat.vue"),
        meta: {
          title: "其它费用统计",
        },
      },
      {
        path: "/Outpatient/AuditingSTATS/ReviewerFeesStat",
        name: "ReviewerFeesStat",
        component: () => import("../views/Home/Outpatient/AuditingSTATS/ReviewerFeesStat.vue"),
        meta: {
          title: "审方费用统计",
        },
      },
      {
        path: "/Outpatient/AuditingSTATS/DepatmentStat",
        name: "DepatmentStat",
        component: () => import("../views/Home/Outpatient/AuditingSTATS/DepatmentStat.vue"),
        meta: {
          title: "部门费用统计",
        },
      },
    ],
  },
  {
    path: "/System",
    name: "System",
    component: () => import("../views/Home/index.vue"),
    meta: {
      title: "系统设置",
    },
    children: [
      {
        path: "/OutpatientIndex" /*首页*/,
        name: "OutpatientIndex",
        component: () => import("../views/Home/index/OutpatientIndex.vue"),
      },
      {
        path: "/index" /*首页*/,
        name: "index",
        component: () => import("../views/Home/index/index.vue"),
      },
      {
        path: "/square" /*选品广场*/,
        name: "square",
        component: () => import("../views/Home/index/square.vue"),
      },
      {
        path: "/squareDetail" /*选品广场*/,
        name: "squareDetail",
        component: () => import("../views/Home/index/squareDetail.vue"),
      },
      {
        path: "/System/User" /*管理员管理*/,
        name: "User",
        component: () => import("../views/Home/System/User.vue"),
        meta: {
          title: "管理员管理",
        },
      },
      {
        path: "/System/Role" /*部门管理*/,
        name: "Role",
        component: () => import("../views/Home/System/Role.vue"),
        meta: {
          title: "角色管理",
        },
      },
      {
        path: "/System/department" /*部门管理*/,
        name: "department",
        component: () => import("../views/Home/System/department.vue"),
        meta: {
          title: "部门管理",
        },
      },
      {
        path: "/System/Post" /*职务管理*/,
        name: "Post",
        component: () => import("../views/Home/System/Post.vue"),
        meta: {
          title: "职务管理",
        },
      },
      {
        path: "/System/Invoice" /*发票信息管理*/,
        name: "Invoice",
        component: () => import("../views/Home/System/Invoice.vue"),
        meta: {
          title: "发票信息管理",
        },
      },
      {
        path: "/System/BankCard" /*银行卡管理*/,
        name: "BankCard",
        component: () => import("../views/Home/System/BankCard.vue"),
        meta: {
          title: "银行卡管理",
        },
      },
      {
        path: "/System/MsgList" /*银行卡管理*/,
        name: "MsgList",
        component: () => import("../views/Home/System/MsgList.vue"),
        meta: {
          title: "消息通知",
        },
      },
      {
        path: "/System/Operation" /*银行卡管理*/,
        name: "Operation",
        component: () => import("../views/Home/System/Operation.vue"),
        meta: {
          title: "操作日志",
        },
      },
    ],
  },
  {
    path: "/Finance",
    name: "Finance",
    component: () => import("../views/Home/index.vue"),
    meta: {
      title: "财务管理",
    },
    children: [
      {
        path: "/Finance/Order" /*续费升级订单*/,
        name: "Order",
        component: () => import("../views/Home/Finance/Order.vue"),
        meta: {
          title: "续费升级订单",
        },
      },
      {
        path: "/Finance/OrderDetail" /*订单详情*/,
        name: "OrderDetail",
        component: () => import("../views/Home/Finance/OrderDetail.vue"),
        meta: {
          title: "续费升级订单",
        },
      },
      {
        path: "/Finance/myOrderDetail" /*订单详情*/,
        name: "myOrderDetail",
        component: () => import("../views/Home/Finance/myOrderDetail.vue"),
        meta: {
          title: "订单明细",
        },
      },
      {
        path: "/Finance/Invoice" /*发票记录*/,
        name: "InvoiceRecord",
        component: () => import("../views/Home/Finance/Invoice.vue"),
        meta: {
          title: "发票记录",
        },
      },
      {
        path: "/Finance/Withdrawal" /*提现管理*/,
        name: "Withdrawal",
        component: () => import("../views/Home/Finance/Withdrawal.vue"),
        meta: {
          title: "提现管理",
        },
      },
      {
        path: "/Finance/Bill" /*账单管理*/,
        name: "Bill",
        component: () => import("../views/Home/Finance/Bill.vue"),
        meta: {
          title: "账单管理",
        },
      },
      {
        path: "/Finance/BalanceDetail" /*余额明细*/,
        name: "BalanceDetail",
        component: () => import("../views/Home/Finance/BalanceDetail.vue"),
        meta: {
          title: "余额明细",
        },
      },
      {
        path: "/Finance/Profit" /*分销获利统计*/,
        name: "Profit",
        component: () => import("../views/Home/Finance/Profit.vue"),
        meta: {
          title: "分销获利明细",
        },
      },
      {
        path: "/Finance/PayPage" /*支付页面*/,
        name: "PayPage",
        component: () => import("../views/Home/Finance/PayPage.vue"),
        meta: {
          title: "支付",
        },
      },
      {
        path: "/Finance/ShareOutBonus" /*公益分红*/,
        name: "ShareOutBonus",
        component: () => import("../views/Home/Finance/ShareOutBonus.vue"),
        meta: {
          title: "统一分红",
        },
      },
      {
        path: "/Finance/CustomerRecord" /*公益分红*/,
        name: "CustomerRecord",
        component: () => import("../views/Home/Finance/CustomerRecord.vue"),
        meta: {
          title: "历史交易用户分红",
        },
      },
      {
        path: "/Finance/CustomerRecordDetailed" /*公益分红明细*/,
        name: "CustomerRecordDetailed",
        component: () => import("../views/Home/Finance/CustomerRecordDetailed.vue"),
        meta: {
          title: "历史交易用户资金明细",
        },
      },
      {
        path: "/Finance/BonusRecordCommander" /*公益分红*/,
        name: "BonusRecordCommander",
        component: () => import("../views/Home/Finance/BonusRecordCommander.vue"),
        meta: {
          title: "团队分红",
        },
      },
      {
        path: "/Finance/TeamPerformance" /*公益分红*/,
        name: "TeamPerformance",
        component: () => import("../views/Home/Finance/TeamPerformance.vue"),
        meta: {
          title: "绩效管理",
        },
      },
      {
        path: "/Finance/CommandeTeamPerformance" /*公益分红*/,
        name: "CommandeTeamPerformance",
        component: () => import("../views/Home/Finance/CommandeTeamPerformance.vue"),
        meta: {
          title: "团绩效管理",
        },
      },
      {
        path: "/Finance/CommandeTeamPerformanceDetail" /*公益分红*/,
        name: "CommandeTeamPerformanceDetail",
        component: () => import("../views/Home/Finance/CommandeTeamPerformanceDetail.vue"),
        meta: {
          title: "团绩效发放详情",
        },
      },
    ],
  },
  {
    path: "/",
    name: "STATS",
    component: () => import("../views/Home/index.vue"),
    meta: {
      title: "数据统计",
    },
    children: [
      {
        path: "/STATS/Organize" /*机构时长统计*/,
        name: "STATSOrganize",
        component: () => import("../views/Home/STATS/Organize.vue"),
        meta: {
          title: "机构时长统计",
        },
      },
      {
        path: "/STATS/Staff" /*员工时长统计*/,
        name: "STATSStaff",
        component: () => import("../views/Home/STATS/Staff.vue"),
        meta: {
          title: "员工时长统计",
        },
      },
      {
        path: "/STATS/Profit" /*分销获利统计*/,
        name: "Profit",
        component: () => import("../views/Home/STATS/Profit.vue"),
        meta: {
          title: "分销获利统计",
        },
      },
      {
        path: "/STATS/testRecord" /*用户测试记录*/,
        name: "testRecord",
        component: () => import("../views/Home/STATS/testRecord.vue"),
        meta: {
          title: "用户测试记录",
        },
      },
      {
        path: "/STATS/ProfitDetail" /*分销获利统计*/,
        name: "ProfitDetail",
        component: () => import("../views/Home/STATS/ProfitDetail.vue"),
        meta: {
          title: "分销获利明细",
        },
      },
      {
        path: "/STATS/ShareDesc" /*分享获利统计*/,
        name: "ShareDesc",
        component: () => import("../views/Home/STATS/ShareDesc.vue"),
        meta: {
          title: "分享获利统计",
        },
      },
      {
        path: "/STATS/ShareDescInfo" /*分享获利明细*/,
        name: "ShareDescInfo",
        component: () => import("../views/Home/STATS/ShareDescInfo.vue"),
        meta: {
          title: "分享获利明细",
        },
      },
      {
        path: "/STATS/shop/OwnStats" /*商品统计*/,
        name: "OwnStats",
        component: () => import("../views/Home/STATS/shop/OwnStats.vue"),
        meta: {
          title: "商品统计",
        },
      },
      {
        path: "/STATS/shop/SalesDetail" /*交易明细*/,
        name: "SalesDetail",
        component: () => import("../views/Home/STATS/shop/SalesDetail.vue"),
        meta: {
          title: "交易明细",
        },
      },
      {
        path: "/STATS/order/OwnStats" /*订单统计*/,
        name: "OwnStats",
        component: () => import("../views/Home/STATS/order/OwnStats.vue"),
        meta: {
          title: "订单统计",
        },
      },
      {
        path: "/STATS/videos/OwnSales" /*视频统计*/,
        name: "OwnSales",
        component: () => import("../views/Home/STATS/videos/OwnSales.vue"),
        meta: {
          title: "视频统计",
        },
      },
      {
        path: "/STATS/ShareOutBonusDetailed" /*公益分红明细*/,
        name: "ShareOutBonusDetailed",
        component: () => import("../views/Home/STATS/ShareOutBonusDetailed.vue"),
        meta: {
          title: "统一分红资金明细",
        },
      },
      {
        path: "/STATS/BonusRecord" /*公益分红记录*/,
        name: "BonusRecord",
        component: () => import("../views/Home/STATS/BonusRecord.vue"),
        meta: {
          title: "统一分红记录",
        },
      },
      {
        path: "/STATS/BonusRecordCommander" /*公益分红记录*/,
        name: "BonusRecordCommander",
        component: () => import("../views/Home/STATS/BonusRecordCommander.vue"),
        meta: {
          title: "团队分红记录",
        },
      },
      {
        path: "/STATS/CustomerPublicShareRecord" /*交易用户分红*/,
        name: "CustomerPublicShareRecord",
        component: () => import("../views/Home/STATS/CustomerPublicShareRecord.vue"),
        meta: {
          title: "交易用户分红统计",
        },
      },
      {
        path: "/STATS/Company" /*单位时间统计*/,
        name: "Company",
        component: () => import("../views/Home/STATS/Company.vue"),
      },
      {
        path: "/STATS/DiseaseStats" /*疾病时间统计*/,
        name: "DiseaseStats",
        component: () => import("../views/Home/STATS/DiseaseStats.vue"),
      },
      {
        path: "/STATS/DrugStats" /*药品时间统计*/,
        name: "DrugStats",
        component: () => import("../views/Home/STATS/DrugStats.vue"),
      },
      {
        path: "/STATS/OtherStats" /*其他时间统计*/,
        name: "OtherStats",
        component: () => import("../views/Home/STATS/OtherStats.vue"),
      },
      {
        path: "/STATS/membershipStatistics" /*会员账单统计*/,
        name: "membershipStatistics",
        component: () => import("../views/Home/STATS/membershipStatistics.vue"),
      },
      {
        path: "/STATS/organizationStatistics" /*机构账单统计*/,
        name: "organizationStatistics",
        component: () => import("../views/Home/STATS/organizationStatistics.vue"),
      },
      {
        path: "/STATS/Hospital" /*医院统计*/,
        name: "Hospital",
        component: () => import("../views/Home/STATS/Hospital.vue"),
      },
      {
        path: "/STATS/Doctor" /*医生统计*/,
        name: "Doctor",
        component: () => import("../views/Home/STATS/Doctor.vue"),
      },
      {
        path: "/STATS/Referral" /*转诊统计*/,
        name: "Referral",
        component: () => import("../views/Home/STATS/Referral.vue"),
      },
      {
        path: "/STATS/Treated" /*接诊统计*/,
        name: "Treated",
        component: () => import("../views/Home/STATS/Treated.vue"),
      },
      {
        path: "/STATS/XDiseaseCollection" /*疾病收藏统计-西医*/,
        name: "XDiseaseCollection",
        component: () => import("../views/Home/STATS/XDiseaseCollection.vue"),
      },
      {
        path: "/STATS/ZDiseaseCollection" /*疾病收藏统计-中医*/,
        name: "ZDiseaseCollection",
        component: () => import("../views/Home/STATS/ZDiseaseCollection.vue"),
      },
      {
        path: "/STATS/XDrugCollection" /*药品收藏统计-西药*/,
        name: "XDrugCollection",
        component: () => import("../views/Home/STATS/XDrugCollection.vue"),
      },
      {
        path: "/STATS/ZDrugCollection" /*药品收藏统计-中成药*/,
        name: "ZDrugCollection",
        component: () => import("../views/Home/STATS/ZDrugCollection.vue"),
      },
      {
        path: "/STATS/MaterialCollection" /*中药收藏统计*/,
        name: "MaterialCollection",
        component: () => import("../views/Home/STATS/MaterialCollection.vue"),
      },
      {
        path: "/STATS/HerbalCollection" /*中药方剂收藏统计*/,
        name: "HerbalCollection",
        component: () => import("../views/Home/STATS/HerbalCollection.vue"),
      },
      {
        path: "/STATS/FoodCollection" /*食疗药膳收藏统计*/,
        name: "FoodCollection",
        component: () => import("../views/Home/STATS/FoodCollection.vue"),
      },
      ,
      {
        path: "/STATS/Share" /*公益分红记录*/,
        name: "Share",
        component: () => import("../views/Home/STATS/Share.vue"),
        meta: {
          title: "会员分红统计",
        },
      },
      {
        path: "/STATS/ShareDetail" /*公益分红记录*/,
        name: "ShareDetail",
        component: () => import("../views/Home/STATS/ShareDetail.vue"),
        meta: {
          title: "会员分红详情",
        },
      },
      {
        path: "/STATS/Consumption" /*公益分红记录*/,
        name: "Consumption",
        component: () => import("../views/Home/STATS/Consumption.vue"),
        meta: {
          title: "会员消费统计",
        },
      },
      {
        path: "/STATS/ConsumptionDetail" /*公益分红记录*/,
        name: "ConsumptionDetail",
        component: () => import("../views/Home/STATS/ConsumptionDetail.vue"),
        meta: {
          title: "消费明细",
        },
      },
      {
        path: "/STATS/ShopConsumeAmountDetail" /*公益分红记录*/,
        name: "ShopConsumeAmountDetail",
        component: () => import("../views/Home/STATS/ShopConsumeAmountDetail.vue"),
        meta: {
          title: "商家消费明细",
        },
      },
    ],
  },
  {
    path: "/",
    name: "ZZ",
    component: () => import("../views/Home/index.vue"),
    meta: {
      title: "用户管理",
    },
    children: [
      {
        path: "/ZZ/Staff" /*员工管理*/,
        name: "Staff",
        component: () => import("../views/Home/ZZ/Staff.vue"),
        meta: {
          title: "用户管理",
        },
      },
      {
        path: "/ZZ/ResetPassword" /*患者管理*/,
        name: "ResetPassword",
        component: () => import("../views/Home/ZZ/ResetPassword.vue"),
        meta: {
          title: "重置操作密码",
        },
      },
      {
        path: "/ZZ/VipOrder" /*患者管理*/,
        name: "VipOrder",
        component: () => import("../views/Home/ZZ/VipOrder.vue"),
        meta: {
          title: "会员续费记录",
        },
      },
      {
        path: "/ZZ/DepositList" /*患者管理*/,
        name: "DepositList",
        component: () => import("../views/Home/ZZ/DepositList.vue"),
        meta: {
          title: "会员押金统计",
        },
      },
    ],
  },
  {
    path: "/",
    name: "Referral",
    component: () => import("../views/Home/index.vue"),
    meta: {
      title: "转诊管理",
    },
    children: [
      {
        path: "/Referral/Referral" /*员工管理*/,
        name: "Referral",
        component: () => import("../views/Home/Referral/Referral.vue"),
        meta: {
          title: "转诊管理",
        },
      },
      {
        path: "/Referral/Treated" /*分销员管理*/,
        name: "Treated",
        component: () => import("../views/Home/Referral/Treated.vue"),
        meta: {
          title: "接诊管理",
        },
      },
    ],
  },
  {
    path: '/',
    name: 'User',
    component: () => import('../views/Home/index.vue'),
    meta: {
      title: '用户管理'
    },
    children: [
      {
        path: '/User/Staff',/*员工管理*/
        name: 'Staff',
        component: () => import('../views/Home/User/Staff.vue'),
        meta: {
          title: '内部员工管理'
        },
      }, 
      {
        path: '/User/Business',/*业务员管理*/
        name: 'Business',
        component: () => import('../views/Home/User/Business.vue'),
        meta: {
          title: '业务员管理'
        },
      }, 
      {
        path: '/User/BusDepartment',/*业务组管理*/
        name: 'BusDepartment',
        component: () => import('../views/Home/User/BusDepartment.vue'),
        meta: {
          title: '业务组管理'
        },
      }, 
      {
        path: '/User/Druggist',/*药剂师管理*/
        name: 'Druggist',
        component: () => import('../views/Home/User/Druggist.vue'),
        meta: {
          title: '药剂师管理'
        },
      }, 
      {
        path: '/User/CertificateAuditing',/*药剂师管理*/
        name: 'CertificateAuditing',
        component: () => import('../views/Home/User/CertificateAuditing.vue'),
        meta: {
          title: '药剂师管理'
        },
      }, 
      {
        path: '/User/Hardware',/*员工管理*/
        name: 'Hardware',
        component: () => import('../views/Home/User/Hardware.vue'),
        meta: {
          title: '大屏管理'
        },
      },{
        path: '/User/AuditingStaff',/*员工管理*/
        name: 'AuditingStaff',
        component: () => import('../views/Home/User/AuditingStaff.vue'),
        meta: {
          title: '内部员工管理'
        },
      }, {
        path: '/User/Patients', /*患者管理*/
        name: 'Patients',
        component: () => import('../views/Home/User/Patients.vue'),
        meta: {
          title: '历史患者'
        }
      }, {
        path: '/User/DiagnosticRecord', /*患者管理*/
        name: 'DiagnosticRecord',
        component: () => import('../views/Home/User/DiagnosticRecord.vue'),
        meta: {
          title: '就诊记录'
        }
      }, {
        path: '/User/UserManager',/*所有用户管理*/
        name: 'UserManager',
        component: () => import('../views/Home/User/UserManager.vue'),
        meta: {
          title: '所有用户管理'
        },
      }, {
        path: '/User/Distributor',/*分销员管理*/
        name: 'Distributor',
        component: () => import('../views/Home/User/Distributor.vue'),
        meta: {
          title: '私域会员管理'
        },
      }, {
        path: '/User/ShareOutBonusDetailedCommander', /*团队收益明细*/
        name: 'ShareOutBonusDetailedCommander',
        component: () => import('../views/Home/User/ShareOutBonusDetailedCommander.vue'),
        meta: {
          title: '团队收益明细'
        }
      }, {
        path: '/User/BonusRecordCommander', /*团队收益明细*/
        name: 'BonusRecordCommander',
        component: () => import('../views/Home/User/BonusRecordCommander.vue'),
        meta: {
          title: '团队分红纪录'
        }
      }, {
        path: '/User/SubDistributor',/*下级用户*/
        name: 'SubDistributor',
        component: () => import('../views/Home/User/SubDistributor.vue'),
        meta: {
          title: '下级用户'
        },
      }, {
        path: '/User/Organization',/*下级机构用户*/
        name: 'Organization',
        component: () => import('../views/Home/User/Organization.vue'),
      }, {
        path: '/User/OrganizeUser',/*下级机构用户*/
        name: 'OrganizeUser',
        component: () => import('../views/Home/User/OrganizeUser.vue'),
      }, {
        path: '/User/SubStaff',/*下级员工账号*/
        name: 'SubStaff',
        component: () => import('../views/Home/User/SubStaff.vue'),
        meta: {
          title: '下级员工管理'
        },
      }, {
        path: '/User/Member',/*私域会员*/
        name: 'Member',
        component: () => import('../views/Home/User/Member.vue'),
        meta: {
          title: '历史交易用户'
        },
      }, {
        path: '/User/wxpay',/*微信支付*/
        name: 'wxpay',
        component: () => import('../views/Home/User/wxpay.vue'),
        meta: {
          title: '微信支付'
        },
      }, {
        path: '/User/Alipay',/*支付宝支付*/
        name: 'Alipay',
        component: () => import('../views/Home/User/Alipay.vue'),
        meta: {
          title: '支付宝支付'
        },
      },
      {
        path: '/User/BroughtAccount',/*对公账户*/
        name: 'BroughtAccount',
        component: () => import('../views/Home/User/BroughtAccount.vue'),
        meta: {
          title: '对公账户'
        },
      },
    ]
  },
  {
    path: "/",
    name: "Messages",
    component: () => import("../views/Home/index.vue"),
    meta: {
      title: "消息管理",
    },
    children: [
      {
        path: "/Message/Messages" /*消息管理*/,
        name: "Messages",
        component: () => import("../views/Home/Message/Messages.vue"),
        meta: {
          title: "消息管理",
        },
      },
    ],
  },
  {
    path: "/",
    name: "TestRecord",
    component: () => import("../views/Home/index.vue"),
    meta: {
      title: "测试记录",
    },
    children: [
      {
        path: "/TestRecord/SelfTest" /*消息管理*/,
        name: "SelfTest",
        component: () => import("../views/Home/TestRecord/SelfTest.vue"),
        meta: {
          title: "健康自测记录",
        },
      },
      {
        path: "/TestRecord/InquiryTest" /*消息管理*/,
        name: "InquiryTest",
        component: () => import("../views/Home/TestRecord/InquiryTest.vue"),
        meta: {
          title: "问诊记录",
        },
      },
      {
        path: "/TestRecord/UserManager" /*消息管理*/,
        name: "UserManager",
        component: () => import("../views/Home/TestRecord/UserManager.vue"),
        meta: {
          title: "员工测试记录",
        },
      },
      {
        path: "/TestRecord/AnswerUserManager" /*消息管理*/,
        name: "AnswerUserManager",
        component: () => import("../views/Home/TestRecord/AnswerUserManager.vue"),
        meta: {
          title: "测试人",
        },
      },
      {
        path: "/TestRecord/AnswerUserTest" /*消息管理*/,
        name: "AnswerUserTest",
        component: () => import("../views/Home/TestRecord/AnswerUserTest.vue"),
        meta: {
          title: "测试人测试记录",
        },
      },
      {
        path: "/TestRecord/AnswerUserInquiryTest" /*消息管理*/,
        name: "AnswerUserInquiryTest",
        component: () => import("../views/Home/TestRecord/AnswerUserInquiryTest.vue"),
        meta: {
          title: "测试人问诊记录",
        },
      },
    ],
  },
  {
    path: "/",
    name: "shopMall",
    component: () => import("../views/Home/index.vue"),
    meta: {
      title: "店铺功能",
    },
    children: [
      {
        path: "/ShopMall/index" /*店铺首页*/,
        name: "ShopIndex",
        component: () => import("../views/Home/ShopMall/index.vue"),
        meta: {
          title: "店铺管理",
        },
      },
      {
        path: "/ShopMall/Apply" /*店铺申请*/,
        name: "ShopApply",
        component: () => import("../views/Home/ShopMall/Apply.vue"),
        meta: {
          title: "店铺申请",
        },
      },
      {
        path: "/ShopMall/changeApply" /*店铺申请*/,
        name: "changeApply",
        component: () => import("../views/Home/ShopMall/changeApply.vue"),
        meta: {
          title: "资质变更",
        },
      },
      {
        path: "/ShopMall/Settings" /*店铺设置*/,
        name: "Settings",
        component: () => import("../views/Home/ShopMall/Settings.vue"),
        meta: {
          title: "店铺设置",
        },
      },
      {
        path: "/ShopMall/Freight" /*运费模板*/,
        name: "Freight",
        component: () => import("../views/Home/ShopMall/Freight.vue"),
        meta: {
          title: "运费模板管理",
        },
      },
      {
        path: "/ShopMall/FreightAdd" /*添加运费模板*/,
        name: "FreightAdd",
        component: () => import("../views/Home/ShopMall/FreightAdd.vue"),
        meta: {
          title: "添加运费模板",
        },
      },
      {
        path: "/ShopMall/commodity" /*商品列表*/,
        name: "commodity",
        component: () => import("../views/Home/ShopMall/commodity.vue"),
        meta: {
          title: "普通商品",
        },
      },
      {
        path: "/ShopMall/auditCommodity" /*商品列表*/,
        name: "auditCommodity",
        component: () => import("../views/Home/ShopMall/auditCommodity.vue"),
        meta: {
          title: "待审核商品",
        },
      },
      {
        path: "/ShopMall/seedBackCommodity" /*商品列表*/,
        name: "seedBackCommodity",
        component: () => import("../views/Home/ShopMall/seedBackCommodity.vue"),
        meta: {
          title: "被驳回商品",
        },
      },
      {
        path: "/ShopMall/addCommodity" /*新增商品*/,
        name: "addCommodity",
        component: () => import("../views/Home/ShopMall/addCommodity.vue"),
        meta: {
          title: "新增商品",
        },
      },
      {
        path: "/ShopMall/integralCommodity" /*积分商品库*/,
        name: "integralCommodity",
        component: () => import("../views/Home/ShopMall/integralCommodity.vue"),
        meta: {
          title: "积分商品",
        },
      },
      {
        path: "/ShopMall/addIntegralCommodity" /*新增积分商品*/,
        name: "addIntegralCommodity",
        component: () => import("../views/Home/ShopMall/addIntegralCommodity.vue"),
        meta: {
          title: "新增积分商品",
        },
      },
      {
        path: "/ShopMall/editCommodity" /*编辑商品*/,
        name: "editCommodity",
        component: () => import("../views/Home/ShopMall/editCommodity.vue"),
        meta: {
          title: "编辑商品",
        },
      },
      {
        path: "/ShopMall/editIntegralCommodity" /*编辑积分商品*/,
        name: "editIntegralCommodity",
        component: () => import("../views/Home/ShopMall/editIntegralCommodity.vue"),
        meta: {
          title: "编辑积分商品",
        },
      },
      {
        path: "/ShopMall/addPrescription" /*新增处方药*/,
        name: "addPrescription",
        component: () => import("../views/Home/ShopMall/addPrescription.vue"),
        meta: {
          title: "新增处方药",
        },
      },
      {
        path: "/ShopMall/prescription" /*处方药库*/,
        name: "prescription",
        component: () => import("../views/Home/ShopMall/prescription.vue"),
        meta: {
          title: "处方药",
        },
      },
      {
        path: "/ShopMall/Order/OwnOrder" /*订单列表*/,
        name: "OwnOrder",
        component: () => import("../views/Home/ShopMall/Order/OwnOrder.vue"),
        meta: {
          title: "商品订单",
        },
      },
      {
        path: "/ShopMall/Order/IntegralOwnOrder" /*积分订单列表*/,
        name: "IntegralOwnOrder",
        component: () => import("../views/Home/ShopMall/Order/IntegralOwnOrder.vue"),
        meta: {
          title: "积分订单列表",
        },
      },
      {
        path: "/ShopMall/Order/OwnRefund" /*退单申请*/,
        name: "OwnRefund",
        component: () => import("../views/Home/ShopMall/Order/OwnRefund.vue"),
        meta: {
          title: "退单申请管理",
        },
      },
      {
        path: "/ShopMall/Order/RefundList" /*退单记录*/,
        name: "RefundList",
        component: () => import("../views/Home/ShopMall/Order/RefundList.vue"),
        meta: {
          title: "历史退单记录",
        },
      },
      {
        path: "/ShopMall/Album" /*相册库*/,
        name: "Album",
        component: () => import("../views/Home/ShopMall/Album.vue"),
        meta: {
          title: "相册库",
        },
      },
      {
        path: "/ShopMall/AlbumDetail" /*相册库*/,
        name: "AlbumDetail",
        component: () => import("../views/Home/ShopMall/AlbumDetail.vue"),
        meta: {
          title: "素材库相册",
        },
      },
    ],
  },
  {
    path: "/",
    name: "videos",
    component: () => import("../views/Home/index.vue"),
    meta: {
      title: "视频管理",
    },
    children: [
      {
        path: "/Videos/Alone" /*单视频管理*/,
        name: "Alone",
        component: () => import("../views/Home/Videos/Alone.vue"),
        meta: {
          title: "单视频",
        },
      },
      {
        path: "/Videos/CollectionVideos" /*合辑视频管理*/,
        name: "OwnCollectionVideos",
        component: () => import("../views/Home/Videos/CollectionVideos.vue"),
        meta: {
          title: "视频合辑管理",
        },
      },
      {
        path: "/Videos/Collection" /*合辑管理*/,
        name: "Collection",
        component: () => import("../views/Home/Videos/Collection.vue"),
        meta: {
          title: "合辑管理",
        },
      },
      {
        path: "/Videos/TradeList" /*交易详情*/,
        name: "TradeList",
        component: () => import("../views/Home/Videos/TradeList.vue"),
        meta: {
          title: "交易详情",
        },
      },
      {
        path: "/Videos/AddCollection" /*新增-编辑视频合辑*/,
        name: "AddCollection",
        component: () => import("../views/Home/Videos/AddCollection.vue"),
      },
      {
        path: "/Videos/AddVideos" /*新增-编辑视频*/,
        name: "AddVideos",
        component: () => import("../views/Home/Videos/AddVideos.vue"),
      },
    ],
  },
  {
    path: "/",
    name: "STATS",
    component: () => import("../views/Home/index.vue"),
    meta: {
      title: "医疗专属服务",
    },
    children: [
      {
        path: "/Patient/Patient" /*患者管理*/,
        name: "Patient",
        component: () => import("../views/Home/Patient/Patient.vue"),
        meta: {
          title: "患者管理",
        },
      },
      {
        path: "/Patient/AddPatient" /*新增/编辑患者*/,
        name: "AddPatient",
        component: () => import("../views/Home/Patient/AddPatient.vue"),
        meta: {
          title: "新增患者",
        },
      },
      {
        path: "/Patient/DiagnosticRecord" /*复诊记录*/,
        name: "DiagnosticRecord",
        component: () => import("../views/Home/Patient/DiagnosticRecord.vue"),
        meta: {
          title: "复诊记录",
        },
      },
      {
        path: "/Patient/AddDiagnostic" /*添加复诊*/,
        name: "AddDiagnostic",
        component: () => import("../views/Home/Patient/AddDiagnostic.vue"),
        meta: {
          title: "添加复诊",
        },
      },
      {
        path: "/Patient/Prescription" /*处方审查*/,
        name: "Prescription",
        component: () => import("../views/Home/Patient/Prescription.vue"),
        meta: {
          title: "处方审查",
        },
      },
      {
        path: "/Patient/Prescribing" /*开具处方*/,
        name: "Prescribing",
        component: () => import("../views/Home/Patient/Prescribing.vue"),
        meta: {
          title: "开具处方",
        },
      },
      {
        path: "/Patient/PrescriptionReview" /*处方药审核*/,
        name: "PrescriptionReview",
        component: () => import("../views/Home/Patient/PrescriptionReview.vue"),
        meta: {
          title: "处方药审核",
        },
      },
      {
        path: "/Patient/AuditingPrescription" /*审核*/,
        name: "AuditingPrescription",
        component: () => import("../views/Home/Patient/AuditingPrescription.vue"),
        meta: {
          title: "审核",
        },
      },
      {
        path: "/Patient/Await" /*处方审查*/,
        name: "Await",
        component: () => import("../views/Home/Patient/Await.vue"),
        meta: {
          title: "待取药",
        },
      },
      {
        path: "/Patient/Completed" /*开具处方*/,
        name: "Completed",
        component: () => import("../views/Home/Patient/Completed.vue"),
        meta: {
          title: "已完成",
        },
      },
    ],
  },
  {
    path: "/",
    name: "Evaluate",
    component: () => import("../views/Home/index.vue"),
    meta: {
      title: "评论管理",
    },
    children: [
      {
        path: "/Evaluate/Videos" /*单视频管理*/,
        name: "Videos",
        component: () => import("../views/Home/Evaluate/Videos.vue"),
        meta: {
          title: "视频评论管理",
        },
      },
      {
        path: "/Evaluate/Order" /*合辑管理*/,
        name: "Order",
        component: () => import("../views/Home/Evaluate/Order.vue"),
        meta: {
          title: "商品订单评论",
        },
      },
    ],
  },
  {
    path: "/Personal/Organization",
    name: "Personal",
    component: () => import("../views/Home/index.vue"),
    meta: {
      title: "个人中心",
    },
    children: [
      {
        path: "/Personal/Organization" /*机构信息*/,
        name: "Organization",
        component: () => import("../views/Home/Personal/Organization.vue"),
        meta: {
          title: "机构信息",
        },
      },
      {
        path: "/Personal/ResetPwd" /*修改密码*/,
        name: "ResetPwd",
        component: () => import("../views/Home/Personal/ResetPwd.vue"),
        meta: {
          title: "修改密码",
        },
      },
    ],
  },
]

const router = new VueRouter({
  routes,
})

export default router
